import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLs } from '@core/conf/urls';
import { TreeSpecies } from '@ghg/model/tree-species.model';
import { LivestockFeedAdditive } from '@shared/models/material/livestock-feed-addictive.enum';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GhgLookupService {
  constructor(private httpClient: HttpClient) {}

  buildingInsulationLevels() {
    return this.httpClient.get(URLs.BUILDING_INSULATION_LEVEL_ENDPOINT) as Observable<any[]>;
  }

  buildingInfiltrationLevels() {
    return this.httpClient.get(URLs.BUILDING_INFILTRATION_LEVEL_ENDPOINT) as Observable<any[]>;
  }

  heatingTypes() {
    return this.httpClient.get(URLs.BUILDING_HEATING_SYSTEM_ENDPOINT) as Observable<any[]>;
  }

  fuelTypes() {
    return this.httpClient.get(URLs.BUILDING_FUEL_TYPE_ENDPOINT) as Observable<any[]>;
  }

  treeSpecies(): Observable<TreeSpecies[]> {
    return this.httpClient.get(URLs.TREE_SPECIES_ENDPOINT) as Observable<TreeSpecies[]>;
  }

  feedAdditives(): Observable<LivestockFeedAdditive[]> {
    return this.httpClient.get(URLs.LIVESTOCK_FEED_ADDITIVE_ENDPOINT) as Observable<LivestockFeedAdditive[]>;
  }

  greenhouseCoveringMaterial() {
    return this.httpClient.get(URLs.GREENHOUSE_COVERING_MATERIAL_ENDPOINT) as Observable<any[]>;
  }

  greenhouseConstructionLevel() {
    return this.httpClient.get(URLs.GREENHOUSE_CONSTRUCTION_LEVEL_ENDPOINT) as Observable<any[]>;
  }

  greenhouseThermalCurtain() {
    return this.httpClient.get(URLs.GREENHOUSE_THERMAL_CURTAIN_ENDPOINT) as Observable<any[]>;
  }

  greenhouseCropType() {
    return this.httpClient.get(URLs.GREENHOUSE_CROP_TYPE_ENDPOINT) as Observable<any[]>;
  }

  greenhouseLightingType() {
    return this.httpClient.get(URLs.GREENHOUSE_LIGHTING_TYPE_ENDPOINT) as Observable<any[]>;
  }

  grainDryerType() {
    return this.httpClient.get(URLs.GRAIN_DRYER_TYPE_ENDPOINT) as Observable<any[]>;
  }

  grainDryerCropType() {
    return this.httpClient.get(URLs.GRAIN_DRYER_CROP_TYPE_ENDPOINT) as Observable<any[]>;
  }
}
