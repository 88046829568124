import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { PlatoWorksheet } from '@plato/model/plato-worksheet.model';
import { WorksheetKeys } from '@shared/models/common/worksheet-keys.enum';
import { of } from 'rxjs';
import { PlatoFertilizerApplication } from '../../shared/models/common/plato-fertilizer-application.model';
import { WorksheetTypeIds } from '../../shared/models/common/worksheet-type-ids.enum';
import { CacheService } from '../../shared/services/cache.service';
import { URLs } from '../conf/urls';
import { Utils } from '../utilities/utils';

@Injectable({ providedIn: 'root' })
export class FertilizerApplicationInterceptor implements HttpInterceptor {

    constructor(private msalService: MsalService,
        private cache: CacheService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // crop nutrient url
        if (req.url.indexOf(URLs.FERTILIZER_APPLICATION_ENDPOINT) > -1) {
            if (!this.isAuthenticated() && (req.method === 'POST')) {
                const app = new PlatoFertilizerApplication(req.body);
                if (!this.platoWorksheet()) {
                    this.toLocalStorage(new PlatoWorksheet());
                }
                const worksheet = this.platoWorksheet();
                worksheet.farms[0].fields[0].fieldSections[0].fertilizerApplications.push(app);
                this.toLocalStorage(worksheet);
                return of(new HttpResponse({ status: 200, body: app }));
            }
            if (!this.isAuthenticated() && (req.method === 'PUT')) {
                req.body.updatedBy = 'frontend';
                const app = new PlatoFertilizerApplication(req.body);
                const worksheet = this.platoWorksheet();
                const index = worksheet.farms[0].fields[0].fieldSections[0].fertilizerApplications.findIndex(v => v.id === app.id);
                worksheet.farms[0].fields[0].fieldSections[0].fertilizerApplications[index] = app;
                this.toLocalStorage(worksheet);
                return of(new HttpResponse({ status: 200, body: app }));
            }
            if (!this.isAuthenticated() && (req.method === 'DELETE')) {
                const id = this.extractIdFromRequest(req.urlWithParams);
                const worksheet = this.platoWorksheet();
                const index = worksheet.farms[0].fields[0].fieldSections[0].fertilizerApplications.findIndex(v => v.id === id);
                worksheet.farms[0].fields[0].fieldSections[0].fertilizerApplications.splice(index, 1);
                this.toLocalStorage(worksheet);
                return of(new HttpResponse({ status: 200, body: true }));
            }
        }
        return next.handle(req);
    }

    private platoWorksheet() {
        return this.cache.worksheets.
            find(v => Utils.matchStr(v.worksheetTypeId, WorksheetTypeIds.PHOSPHORUS_LOSS)) as PlatoWorksheet;
    }

    private isAuthenticated(): boolean {
        return this.msalService.instance.getAllAccounts().length > 0;
    }

    private toLocalStorage(worksheet: PlatoWorksheet) {
        localStorage.setItem(WorksheetKeys.PLATO, JSON.stringify(worksheet));
    }

    private extractIdFromRequest(url: string) {
        return url.split('/')[url.split('/').length - 1];
    }
}
