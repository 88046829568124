import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLs } from '@core/conf/urls';
import { WorksheetType } from '@shared/models/worksheet/WorksheetType';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorksheetTypeLookupService {

  constructor(private httpClient: HttpClient) { }

  worksheetTypes() {
    return this.httpClient.get(URLs.ALL_WORKSHEET_TYPES_ENDPOINT) as Observable<WorksheetType[]>;
  }

}
