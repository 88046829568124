import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstants } from '@core/conf/app-constants';

@Injectable({ providedIn: 'root' })
export class RequestCacheService {

    // in memory cache, this can be replaced with localStorage if needed
    private cache = new Map();

    get(req: HttpRequest<any>): HttpResponse<any> | undefined {
        const cached = this.cache.get(req.urlWithParams);
        return !cached ? undefined : cached.response;
    }

    put(req: HttpRequest<any>, response: HttpResponse<any>): void {
        const url = req.urlWithParams;
        const entry = { url, response, lastRead: Date.now() };
        // cache
        this.cache.set(url, entry);
        // auto cleanze expired cache entries
        this.autoCleanExpiries();
    }

    private autoCleanExpiries() {
        const expiryOffset = Date.now() - AppConstants.CACHE_MAX_AGE;
        this.cache.forEach(cacheEntry => {
            if (cacheEntry.lastRead < expiryOffset) {
                this.cache.delete(cacheEntry.url);
            }
        });
    }
}
