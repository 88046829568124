import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLs } from '@core/conf/urls';
import { tap } from 'rxjs/operators';
import { Constants } from '../../shared/constants/constants';
import { MeasurementSystem } from '../../shared/models/common/measurement-system.enum';
import { Preference } from '../../shared/models/user/preference';
import { CacheService } from '../../shared/services/cache.service';
import { MeasurementService } from '../../shared/services/measurement.service';
import { Utils } from '../utilities/utils';

@Injectable({ providedIn: 'root' })
export class PreferenceInterceptor implements HttpInterceptor {

    constructor(private cache: CacheService, private measurementService: MeasurementService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (this.isInterceptableUrl(req.url)) {
            if (req.method === 'POST' || req.method === 'PUT') {
                // attach preference information to outgoing request
                const worksheet = req.body;
                this.readPreference(worksheet);
                // modify outgoing request
                const requestWithPreference = req.clone({
                    body: worksheet
                });
                return next.handle(requestWithPreference);
            }
        }
        return next.handle(req).pipe(tap(event => {
            // read from response
            if (event instanceof HttpResponse && this.isInterceptableUrl(req.url)) {
                // added object check on event body to ensure worksheet object is returned
                if (req.method === 'GET' && event.status === 200 && Utils.isObject(event.body)) {
                    const worksheet: any = event.body;
                    this.writePreference(worksheet);
                }
            }
        }));
    }

    // todo extend this to support all calculator types later
    private isInterceptableUrl(url: string) {
        return URLs.INTERCEPTABLE_WORKSHEET_PATHS.find(v => url.toUpperCase().indexOf(v.toUpperCase()) > -1);
    }

    private readPreference(worksheet: any) {
        const preference: Preference = this.cache.preference;
        worksheet.langPreference = preference ? preference.languageType : Constants.DEFAULT_LANGUAGE_TYPE;
        // tslint:disable-next-line: max-line-length
        worksheet.measureSystemName = preference ? MeasurementSystem[preference.measurementSystem] : MeasurementSystem[Constants.DEFAULT_MEASUREMENT_SYSTEM];
    }

    private writePreference(worksheet: any) {
        const preference: Preference = this.cache.preference;
        if (preference) {
            preference.measurementSystem = MeasurementSystem[worksheet.measureSystemName as string];
            this.cache.preference = preference;
            this.measurementService.measurementSystem = MeasurementSystem[worksheet.measureSystemName as string];
        }
    }

}
