import { Injectable } from '@angular/core';
import { Constants } from '@shared/constants/constants';
import { APICacheService } from '@shared/services/api-cache.service';
import { CacheService } from '@shared/services/cache.service';
import { LanguageService } from '@shared/services/language.service';
import { MeasurementService } from '@shared/services/measurement.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(
    private apiCacheService: APICacheService,
    private cache: CacheService,
    private measurementService: MeasurementService,
    private languageService: LanguageService
  ) {}

  createAPICache(buildVersion: string): Observable<any> {
    return this.apiCacheService.create(buildVersion).pipe(tap(() => this.finalizeCache()));
  }

  private finalizeCache() {
    Constants['nutrientCodes'] = this.cache.nutrientCodes; // this is bad, removing soon
    this.cache.initPreference();
    this.languageService.languageType = this.cache.preference.languageType;
    this.measurementService.measurementSystem = this.cache.preference.measurementSystem;
  }
}
