import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class BasicAuthInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const contentType = req.headers.get('Content-Type');
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.accessToken()}`,
      'Ocp-Apim-Subscription-Key': environment.serviceSubscriptionKey
    });
    // This is for file upload.
    // Don't set Content-Type for file upload, or it causes error
    if (contentType !== 'multipart/form-data') {
      headers.set('Content-Type', 'application/json');
    }
    const authReq = req.clone({
      headers
    });

    return next.handle(authReq);
  }

  private accessToken() {
    // this token is cached in the sessionStorage after logging in
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key.indexOf('accesstoken') > -1) {
        const obj = JSON.parse(sessionStorage.getItem(key));
        return obj.secret;
      }
    }
    return null;
  }
}
