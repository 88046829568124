export enum WorksheetImportErrorCodes {
  /* File virus scan error */
  IMPT_FILE_SCAN = 'IMPT_001E',
  /* XML file unmarshal error */
  IMPT_INAVLID_XML = 'IMPT_002E',
  /* Un-supported worksheet type - Per Worksheet */
  IMPT_WORKSHEET_TYPE_NOT_SUPPORTED = 'IMPT_003E',
  /* Transform/validation error - Per Worksheet */
  IMPT_INVALID_WORKSHEET = 'IMPT_004E'
}

export enum ImportType {
  AGRI = 'AGRI',
  JSON = 'JSON',
  ERO = 'ERO'
}

export const AcceptedFileExtensions = ['.agri', '.nm3', '.ero', '.xml', '.json'];

export const SupportedMimeTypes = {
  AGRI: ['text/xml'],
  JSON: ['application/json'],
  ERO: ['text/xml']
};
