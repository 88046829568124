import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { SecureHomeComponent } from './secure-home/secure-home.component';
import { CreateNewDialogComponent } from './secure-home/create-new-dialog.component';
import { RenameWorksheetComponent } from './secure-home/rename-worksheet.component';
import { HomeComponent } from './home/home.component';
import { ImportWorksheetComponent } from './secure-home/worksheet-import/worksheet-import.component';
import { ConvertWorksheetComponent } from './secure-home/convert-worksheet/convert-worksheet.component';
import { CopyWorksheetDialogComponent } from './secure-home/copy-worksheet-dialog/copy-worksheet-dialog.component';
import { LabelFilterComponent } from './secure-home/label-filter/label-filter.component';

@NgModule({
  declarations: [
    HomeComponent,
    SecureHomeComponent,
    CreateNewDialogComponent,
    RenameWorksheetComponent,
    ImportWorksheetComponent,
    ConvertWorksheetComponent,
    CopyWorksheetDialogComponent,
    LabelFilterComponent
  ],
  imports: [CommonModule, SharedModule]
})
export class HomeModule {}
