<main class="error-container">
  <div class="article__intro container container--large js-waypoints-trigger-initial anim-appear">
    <div class="article__intro-text">
      <h1 style="color: #3B4B5C;">{{ 'error.page.title' | translate | async}}</h1>
      <h2 style="color: #3B4B5C;">
        <p>{{ 'error.page.content' | translate | async}}</p>
      </h2>
      <ng-container *ngFor="let item of appConstants.HTTP_ERRORS">
        <div *ngIf="error.status === item.id.toString()">{{ 'error.description' | translate | async }}: {{ error.status
          }} - {{ item.i18nkey | translate | async}}</div>
        <!-- <div class="article__intro-text animatable js-anim-trigger anim-appear">
          <div>
            <h3>{{error?.messageEn}}</h3>
          </div>
          <div>
            <h3>{{error?.messageFr}}</h3>
          </div>
          <div>
            <h3>{{error?.timestamp | date}}</h3>
          </div>
        </div> -->
      </ng-container>
    </div>
  </div>
</main>