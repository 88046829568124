import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { HomeModule } from '../home/home.module';
import { SharedModule } from '../shared/shared.module';
import { ErrorComponent } from './components/error/error.component';
import { BasicAuthInterceptor } from './interceptors/basic.auth.interceptor';
import { CachingInterceptor } from './interceptors/caching.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { FertilizerApplicationInterceptor } from './interceptors/fertilizer-application.interceptor';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { MaterialApplicationInterceptor } from './interceptors/material-application.interceptor';
import { MaterialTypeInterceptor } from './interceptors/material-type.interceptor';
import { PreferenceInterceptor } from './interceptors/preference.interceptor';
import { WorksheetInterceptor } from './interceptors/worksheet.interceptor';

export const APP_DATE_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  declarations: [ErrorComponent],
  exports: [SharedModule, ErrorComponent],
  imports: [CommonModule, SharedModule, HomeModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: UserAccountInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: WorksheetInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MaterialApplicationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FertilizerApplicationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MaterialTypeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PreferenceInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class CoreModule {}
