import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConstants } from '@core/conf/app-constants';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {

  private error: any;
  private sub: any;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.error = params;
      console.log('this.error', this.error);
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  get appConstants() {
    return AppConstants;
  }
}
