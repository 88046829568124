import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { URLs } from '@core/conf/urls';
import { WorksheetFactory } from '@home/service/worksheet.factory';
import { of } from 'rxjs';
import { CacheService } from '../../shared/services/cache.service';
import { Utils } from '../utilities/utils';

@Injectable({ providedIn: 'root' })
export class WorksheetInterceptor implements HttpInterceptor {
  constructor(private msalService: MsalService, private worksheetFactory: WorksheetFactory, private cache: CacheService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isAuthenticated()) {
      const key = this.worksheetKeyByUrl(req.url);
      if (key) {
        if (['POST', 'PUT'].indexOf(req.method) > -1) {
          req.body.id = req.body.id;
          this.cache.setWorksheet(req.body, key);
          return of(new HttpResponse({ status: 200, body: req.body }));
        }
        if (req.method === 'GET') {
          const worksheet = this.cache.getWorksheet(key);
          return of(
            new HttpResponse({
              status: 200,
              body: worksheet || this.worksheetFactory.instance(key)
            })
          );
        }
      }
    }
    return next.handle(req);
  }

  private worksheetKeyByUrl(url: string) {
    const path = URLs.INTERCEPTABLE_WORKSHEET_PATHS.find(u => url.indexOf(u) > -1);
    return path ? URLs.WORKSHEET_PATH_MAP[path] : undefined;
  }

  private isAuthenticated(): boolean {
    return this.msalService.instance.getAllAccounts().length > 0;
  }
}
