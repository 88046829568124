import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLs } from '@core/conf/urls';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GnfLookupService {

  constructor(private httpClient: HttpClient) { }

  horticulturalType() {
    return this.httpClient.get(URLs.HORTI_CULTURAL_TYPE_ENDPOINT) as Observable<any[]>;
  }
}