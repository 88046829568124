import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { URLs } from '@core/conf/urls';

@Injectable({
  providedIn: 'root'
})
export class SharedWorksheetImportService {

  constructor(private httpClient: HttpClient) { }

  upload(file): Observable<any> {

    const formData: FormData = new FormData();
    formData.append('file', file);

    const headerOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' })
    };

    // placeholder
    // return of(true);
    return this.httpClient.post(URLs.WORKSHEET_IMPORT_ENDPOINT, formData, headerOptions);
    // return this.httpClient.post('https://api.scanii.com/v2.1/files', formData, headerOptions);
    // return this.httpClient.post('https://dev-agrisuite-apim.azure-api.net/parser', formData, headerOptions);
  }

  import(): Observable<any> {
    return of(undefined);
  }
}
