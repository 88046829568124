import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLs } from '@core/conf/urls';
import { EquationValue } from '@shared/models/common/equation-value.model';
import { Observable } from 'rxjs';
import { FlagUrls } from '../../core/conf/flag-urls';

@Injectable({ providedIn: 'root' })
export class FlagService {

  constructor(private httpClient: HttpClient) { }

  allTypes(): Observable<any> {
    return this.httpClient.get(FlagUrls.FLAG_TYPES_ENDPOINT);
  }

  equationValues(): Observable<EquationValue[]> {
    return this.httpClient.get(URLs.EQUATION_VALUES_ENDPOINT) as Observable<EquationValue[]>;
  }
}
