/* eslint-disable no-undef */
import { ChangeDetectionStrategy, Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from '@core/services/session.service';
import { BaseComponent } from '@shared/components/base.component';
import { Constants } from '@shared/constants/constants';
import { AvailableWorksheetTypeIds, RequiredSignInWorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { WorksheetType } from '@shared/models/worksheet/WorksheetType';
import { takeWhile, tap } from 'rxjs/operators';
import { NewCalculatorDialogComponent } from './new-calculator-dialog/new-calculator-dialog.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent extends BaseComponent implements OnInit {
  availableWorksheets: WorksheetType[];

  constructor(private injector: Injector, private session: SessionService, public dialog: MatDialog) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.router.navigate(['/']);
    this.availableWorksheets = this.cache?.worksheetTypes?.filter(w =>
      (AvailableWorksheetTypeIds as string[])?.includes(w.id?.toLowerCase())
    );
  }

  login(): void {
    this.session.login();
  }

  resetPassword(): void {
    this.session.resetPassword();
  }

  signup(): void {
    this.session.signup();
  }

  navigateTo(key: string): void {
    const route = `/${key}`;
    const worksheet = this.cache.getWorksheet(key);
    if (!worksheet) {
      this.router.navigate([route], { queryParams: { from: Constants.PAGE_FROM_HOME } });
      return;
    }
    this.dialogService
      .instance(NewCalculatorDialogComponent)
      .afterClosed()
      .pipe(
        takeWhile(() => this.alive),
        tap(res => {
          if (res) {
            this.cache.deleteWorksheet(key);
          }
          if (res || res === undefined) {
            this.router.navigate([route], { queryParams: { from: Constants.PAGE_FROM_HOME } });
          }
        })
      )
      .subscribe();
  }

  toggleCardTooltip(cardTooltip: HTMLElement): void {
    if (cardTooltip?.style?.display === '' || cardTooltip?.style?.display === 'none') {
      cardTooltip.style.display = 'block';
    } else {
      cardTooltip.style.display = 'none';
    }
  }

  requiresSignIn(worksheet: WorksheetType): boolean {
    return (RequiredSignInWorksheetTypeIds as string[])?.includes(worksheet?.id?.toLowerCase());
  }
}
