import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { PlatoWorksheet } from '@plato/model/plato-worksheet.model';
import { WorksheetKeys } from '@shared/models/common/worksheet-keys.enum';
import { of } from 'rxjs';
import { MaterialType } from '../../calculators/plato/model/material-type.model';
import { WorksheetTypeIds } from '../../shared/models/common/worksheet-type-ids.enum';
import { CacheService } from '../../shared/services/cache.service';
import { URLs } from '../conf/urls';
import { Utils } from '../utilities/utils';

@Injectable({ providedIn: 'root' })
export class MaterialTypeInterceptor implements HttpInterceptor {

    constructor(private msalService: MsalService,
        private cache: CacheService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (req.url.indexOf(URLs.MATERIAL_TYPE_ENDPOINT) > -1) {
            if (!this.isAuthenticated() && (req.method === 'GET') &&
                req.urlWithParams.indexOf('material-types/worksheet') === -1 &&
                req.urlWithParams.indexOf('is-deletable') === -1) {
                const id = this.extractIdFromRequest(req.urlWithParams);
                const materialType = this.cache.getMaterialType(id);
                return of(new HttpResponse({ status: 200, body: materialType }));
            }
            if (!this.isAuthenticated() && (req.method === 'GET') &&
                req.urlWithParams.indexOf('material-types/worksheet') > -1 &&
                req.urlWithParams.indexOf('is-deletable') === -1) {
                const worksheetId = this.extractWorksheetIdFromRequest(req.urlWithParams);
                const materialTypes = this.cache.getMaterialTypes(worksheetId);
                return of(new HttpResponse({ status: 200, body: materialTypes }));
            }
            if (!this.isAuthenticated() && (req.method === 'GET') &&
                req.urlWithParams.indexOf('is-deletable') > -1) {
                const id = this.extractSecondLastIdFromRequest(req.urlWithParams);
                const worksheet = this.platoWorksheet();
                const materialTypes = this.cache.getMaterialTypes(worksheet.id);
                const materialApplications = worksheet.farms[0].fields[0].fieldSections[0].materialApplications;
                const associatedApplications = materialApplications.
                    filter(v => v.materialTypeId && v.materialTypeId.toUpperCase() === id.toUpperCase());

                if ((!materialApplications || !materialApplications.length) && materialTypes && materialTypes.length > 1) {
                    return of(new HttpResponse({ status: 200, body: true }));
                }

                if (materialApplications && materialApplications.length && materialTypes && materialTypes.length > 1
                    && (!associatedApplications || !associatedApplications.length)) {
                    return of(new HttpResponse({ status: 200, body: true }));
                }

                return of(new HttpResponse({ status: 200, body: false }));
            }
            if (!this.isAuthenticated() && (req.method === 'POST')) {
                const materialType = new MaterialType(req.body);
                // materialType.id = Utils.UUID();
                const worksheet = this.platoWorksheet();
                const materialTypes = this.cache.getMaterialTypes(worksheet.id) || [];
                this.cache.setMaterialTypes(worksheet.id, [...materialTypes, materialType]);
                worksheet.materialTypes = [...materialTypes, materialType];
                this.toLocalStorage(worksheet);
                return of(new HttpResponse({ status: 200, body: materialType }));
            }
            if (!this.isAuthenticated() && (req.method === 'PUT')) {
                const materialType = new MaterialType(req.body);
                const worksheet = this.platoWorksheet();
                const index = worksheet.materialTypes.findIndex(v => v.id === materialType.id);
                const materialTypes = this.cache.getMaterialTypes(worksheet.id);
                materialTypes[index] = materialType;
                this.cache.setMaterialTypes(worksheet.id, materialTypes);
                worksheet.materialTypes = materialTypes;
                this.toLocalStorage(worksheet);
                return of(new HttpResponse({ status: 200, body: materialType }));
            }
            if (!this.isAuthenticated() && (req.method === 'DELETE')) {
                const id = this.extractIdFromRequest(req.urlWithParams);
                const worksheet = this.platoWorksheet();
                const index = worksheet.materialTypes.findIndex(v => v.id === id);
                const materialTypes = this.cache.getMaterialTypes(worksheet.id);
                materialTypes.splice(index, 1);
                this.cache.setMaterialTypes(worksheet.id, materialTypes);
                worksheet.materialTypes = materialTypes;
                this.toLocalStorage(worksheet);
                return of(new HttpResponse({ status: 200, body: true }));
            }
        }
        return next.handle(req);
    }

    private isAuthenticated(): boolean {
        return this.msalService.instance.getAllAccounts().length > 0;
    }

    private platoWorksheet() {
        return this.cache.worksheets.
            find(v => Utils.matchStr(v.worksheetTypeId, WorksheetTypeIds.PHOSPHORUS_LOSS)) as PlatoWorksheet;
    }

    private toLocalStorage(worksheet: PlatoWorksheet) {
        localStorage.setItem(WorksheetKeys.PLATO, JSON.stringify(worksheet));
    }

    private extractIdFromRequest(url: string) {
        if (url.indexOf('material-types/worksheet') === -1) {
            return url.split('/')[url.split('/').length - 1];
        }
        return undefined;
    }

    private extractWorksheetIdFromRequest(url: string) {
        if (url.indexOf('material-types/worksheet') > -1) {
            return url.split('/')[url.split('/').length - 1];
        }
        return undefined;
    }

    private extractSecondLastIdFromRequest(url: string) {
        if (url.indexOf('material-types/worksheet') === -1) {
            return url.split('/')[url.split('/').length - 2];
        }
        return undefined;
    }
}
