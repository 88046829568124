import { ChangeDetectionStrategy, Component, Injector, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { Utils } from '@core/utilities/utils';
import { BaseComponent } from '@shared/components/base.component';
import { FilterMenuComponent } from '@shared/components/filter-menu/filter-menu.component';
import { Label } from '@shared/models/common/label.model';
import { WorksheetLabelLink } from '@shared/models/common/worksheet-label-link.model';
import { Worksheet } from '@shared/models/worksheet/Worksheet';
import { LabelClient } from '@shared/services/label.service';
import { WorksheetLabelLinkClient } from '@shared/services/worksheet-label-link.client';
import { EMPTY, forkJoin, of, switchMap, takeWhile, tap } from 'rxjs';

@Component({
  selector: 'app-label-filter',
  templateUrl: './label-filter.component.html',
  styleUrls: ['./label-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LabelFilterComponent extends BaseComponent implements OnInit {
  @ViewChild('filterMenu', { static: true }) filterMenu: FilterMenuComponent;
  @ViewChild('filterMenuTrigger') filterMenuTrigger: MatMenuTrigger;
  @Input('worksheets') worksheets: Worksheet[];
  filterForm: UntypedFormGroup;

  constructor(
    private injector: Injector,
    private fb: FormBuilder,
    private labelClient: LabelClient,
    private worksheetLabelLinkClient: WorksheetLabelLinkClient
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.configForm();
    this.bindFilterEvents();
    this.load().subscribe();
  }

  load() {
    this.labelFilter.patchValue('label.filter.text');
    this.filterMenu.labelList.reset();
    return forkJoin([this.labelClient.all(), this.worksheetLabelLinkClient.all()]).pipe(
      takeWhile(() => this.alive),
      tap(([labels, links]) => {
        if (!labels?.length || !links?.length) {
          this.worksheets?.forEach(w => (w.labels = []));
          return of(this.worksheets);
        }
        this.bindLabels(links, labels);
        this.onUpdate.emit(this.worksheets);
      })
    );
  }

  private bindLabels(links: WorksheetLabelLink[], labels: Label[]) {
    this.worksheets.forEach(w => {
      w.labels = [];
      const linkedLabels = links.filter(v => Utils.matchStr(v.worksheetId, w.id)).map(v => v.worksheetLabelId);
      linkedLabels?.forEach(id => {
        const label = labels.find(l => Utils.matchStr(id, l.id));
        if (label) {
          w.labels.push(label);
        }
      });
    });
  }

  filterByLabels(selections, clear) {
    const selectedLabels = Object.keys(selections).filter(key => selections[key] === true);
    if (selectedLabels.length) {
      this.labelFilter.patchValue('Label.filter.applied.text');
      const worksheets = this.worksheets.filter(w => {
        return w.labels?.some(l => selectedLabels.indexOf(l.id) > -1);
      });
      this.onUpdate.emit(worksheets);
    } else {
      this.labelFilter.patchValue('label.filter.text');
      this.onUpdate.emit(this.worksheets);
    }
  }

  clearLabels() {
    this.labelFilter.patchValue('label.filter.text');
    this.onUpdate.emit(this.worksheets);
  }

  private configForm() {
    this.filterForm = this.fb.group({
      labelFilter: new UntypedFormControl('label.filter.text')
    });
  }

  private bindFilterEvents() {
    this.labelClient.onUpdate
      .pipe(
        takeWhile(() => this.alive),
        switchMap(res => (res ? this.filterMenu.all().pipe(switchMap(() => this.load())) : EMPTY))
      )
      .subscribe();
  }

  get labelFilter() {
    return this.filterForm?.controls.labelFilter;
  }

  get filterApplied() {
    return this.labelFilter.value === 'Label.filter.applied.text';
  }
}
