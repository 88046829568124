<article data-homeComponent class="flex flex--cols-3">
    <section class="flex-child flex-child--align-top">
        <h1 class="h1">
            {{ "home.content.left.text.welcome.title" | translate | async }}
        </h1>
        <p>
            {{ "home.content.left.text.welcome.content" | translate | async }}
        </p>
    </section>
    <section class="flex-child flex-child--align-top">
        <h2 class="h2">
            {{ "home.content.middle.text.createnew.title" | translate | async }}
        </h2>
        <p>
            {{ "home.content.middle.text.createnew.content" | translate | async }}
        </p>
        <app-loading></app-loading>
        <ul *ngIf="(isLoading$ | async) === false">
            <li *ngFor="let worksheet of availableWorksheets | orderBy : 'displayOrder'" class="flex flex--cols-2">
                <p class="flex-child flex-child--flex-shrink">
                    <ng-container *ngIf="requiresSignIn(worksheet); else otherWorksheets">
                        <a (click)="login()" [tabIndex]="0" (keydown.enter)="login()" href="javascript:void(0);">
							{{ worksheet.description[lang$ | async] }} ({{ "calculator.requires.sign.in" | translate | async }})
						</a>
                    </ng-container>
                    <ng-template #otherWorksheets>
                        <a (click)="navigateTo(worksheet.typeName)" [tabIndex]="0" (keydown.enter)="navigateTo(worksheet.typeName)" href="javascript:void(0);">
                            {{ worksheet.description[lang$ | async] }}
                        </a>
                    </ng-template>
                </p>
                <div class="flex-child flex-child--flex-shrink">
                    <button type="button" class="button button--green-tertiary material-icons" [title]="worksheet.details[lang$ | async]" [tabindex]="0" (click)="toggleCardTooltip(tooltipLink)">
                        info
                    </button>
                </div>
                <div class="flex-child flex-child--full-width" #tooltipLink>
                    {{ worksheet.details[lang$ | async] }}
                </div>
            </li>
        </ul>
    </section>
    <section class="flex-child flex-child--align-top">
        <h2 class="h2">
            {{ "home.content.right.signin.title" | translate | async }}
        </h2>
        <button type="button" mat-stroked-button class="button button--green-primary" (click)="login()" *ngIf="!isAuthenticated">
            {{ "home.content.right.signin.button.caption" | translate | async }}
        </button>
        <p>
            <a id="forgotPassword" href="javascript:void(0);" (click)="resetPassword()">
                {{ "home.content.right.text.forgotpassword" | translate | async }}
            </a>
        </p>
        <hr class="hr" />
        <h2 class="h2">
            {{ "home.content.right.text.getmost" | translate | async }}
        </h2>
        <button type="button" mat-stroked-button class="button button--green-primary" (click)="signup()" *ngIf="!isAuthenticated">
            {{ "home.content.right.createaccount.button.caption" | translate | async }}
        </button>
        <p>
            {{ "home.content.right.text.createaccountallow" | translate | async }}
        </p>
        <ul>
            <li>
                {{ "home.content.right.text.securesave" | translate | async }}
            </li>
            <li>
                {{ "home.content.right.text.sharework" | translate | async }}
            </li>
            <li>
                {{ "home.content.right.text.accessinternet" | translate | async }}
            </li>
        </ul>
    </section>
</article>
