import { environment } from '../../../environments/environment';

export class FlagUrls {
  public static readonly FLAG_ENDPOINT: string = environment.apiEndpointFlagV1;
  public static readonly FLAG_TYPES_ENDPOINT: string = environment.apiEndpointFlagV1 + 'types';
  // urls of organic amendment flags
  public static readonly ENDPOINT_111 = environment.apiEndpointFlagV1 + 'material-application-method';
  public static readonly ENDPOINT_113 = environment.apiEndpointFlagV1 + 'material-application-date-winter';
  public static readonly ENDPOINT_116 = environment.apiEndpointFlagV1 + 'material-application-rate-minimum';
  public static readonly ENDPOINT_117 = environment.apiEndpointFlagV1 + 'material-application-rate-maximum';
  public static readonly ENDPOINT_130 = environment.apiEndpointFlagV1 + 'nutrient-balance-total-N';
}
