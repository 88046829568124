import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstants } from '@core/conf/app-constants';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RequestCacheService } from '../../shared/services/request-cache.service';

@Injectable({ providedIn: 'root' })
export class CachingInterceptor implements HttpInterceptor {

    constructor(private reqCache: RequestCacheService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (this.isCachable(req)) {
            const cachedResponse = this.reqCache.get(req);
            return cachedResponse ? of(cachedResponse) : this.sendRequest(req, next);
        }
        return next.handle(req);
    }

    private sendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    if (this.isCachable(req) && !this.reqCache.get(req)) {
                        this.reqCache.put(req, event);
                    }
                }
            })
        );
    }

    private isCachable(req: HttpRequest<any>): boolean {
        return this.isMethodCacheable(req) &&
            AppConstants.CACHEABLE_URLS.filter(url => req.urlWithParams.indexOf(url) > -1).length > 0;
    }

    private isMethodCacheable(req: HttpRequest<any>): boolean {
        return AppConstants.CACHEABLE_HTTP_METHODS.indexOf(req.method) > -1;
    }
}
