import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { ErrorComponent } from '@core/components/error/error.component';
import { SecureHomeComponent } from '@home/secure-home/secure-home.component';
import { HomeComponent } from '@home/home/home.component';

export const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: 'CROP_NUTRIENT',
    loadChildren: () => import('../../calculators/crop-nutrient/crop-nutrient.module').then(m => m.CropNutrientModule)
  },
  {
    path: 'MATERIAL_APPLICATION',
    loadChildren: () => import('../../calculators/organic-amendment/organic-amendment.module').then(m => m.OrganicAmendmentModule)
  },
  {
    path: 'FERTILIZER_APPLICATION',
    loadChildren: () => import('../../calculators/fertilizer/fertilizer.module').then(m => m.FertilizerModule)
  },
  {
    path: 'PHOSPHORUS_LOSS',
    loadChildren: () => import('../../calculators/plato/plato.module').then(m => m.PlatoModule)
  },
  {
    path: 'FIELD_MANAGEMENT_PLAN_SITE',
    loadChildren: () => import('../../calculators/fmp-site/fmp-site.module').then(m => m.FmpSiteModule)
  },
  {
    path: 'FIELD_MANAGEMENT_PLAN',
    loadChildren: () => import('../../calculators/fmp/fmp.module').then(m => m.FmpModule)
  },
  {
    path: 'MANURE_STORAGE_SIZING_SITE',
    loadChildren: () => import('../../calculators/mstor-site/mstor-site.module').then(m => m.MstorSiteModule)
  },
  {
    path: 'MANURE_STORAGE_SIZING',
    loadChildren: () => import('../../calculators/mstor/mstor.module').then(m => m.MstorModule)
  },
  {
    path: 'MINIMUM_DISTANCE_SEPARATION_1',
    loadChildren: () => import('../../calculators/mds1/mds1.module').then(m => m.Mds1Module)
  },
  {
    path: 'MINIMUM_DISTANCE_SEPARATION_1_SITE',
    loadChildren: () => import('../../calculators/mds1-site/mds1-site.module').then(m => m.Mds1SiteModule)
  },
  {
    path: 'MINIMUM_DISTANCE_SEPARATION_2',
    loadChildren: () => import('../../calculators/mds2/mds2.module').then(m => m.Mds2Module)
  },
  {
    path: 'MINIMUM_DISTANCE_SEPARATION_2_SITE',
    loadChildren: () => import('../../calculators/mds2-site/mds2-site.module').then(m => m.Mds2SiteModule)
  },
  {
    path: 'NUTRIENT_MANAGEMENT_STRATEGY_PLAN',
    loadChildren: () => import('../../calculators/nmsp/nmsp.module').then(m => m.NmspModule)
  },
  {
    path: 'NON_AGRICULTURAL_SOURCE_MATERIAL',
    loadChildren: () => import('../../calculators/nasm/nasm.module').then(m => m.NasmModule)
  },
  {
    path: 'GREENHOUSE_NUTRIENT_FEEDWATER',
    loadChildren: () => import('../../calculators/gnf/gnf.module').then(m => m.GnfModule)
  },
  {
    path: 'GREENHOUSE_GAS',
    loadChildren: () => import('../../calculators/ghg/ghg.module').then(m => m.GhgModule)
  },
  {
    path: 'GREENHOUSE_GAS_SITE',
    loadChildren: () => import('../../calculators/ghg-site/ghg-site.module').then(m => m.GhgSiteModule)
  },
  {
    path: 'AG_EROSION',
    loadChildren: () => import('../../calculators/ag-erosion/ag-erosion.module').then(m => m.AgErosionModule)
  },
  {
    path: 'AG_EROSION_SITE',
    loadChildren: () => import('../../calculators/ag-erosion-site/ag-erosion-site.module').then(m => m.AgErosionSiteModule)
  },
  { path: 'error', component: ErrorComponent },
  { path: 'secure-home', component: SecureHomeComponent, canActivate: [MsalGuard] },
  { path: '**', redirectTo: 'secure-home' }
];
