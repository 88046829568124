import { Observable, Observer } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { AcceptedFileExtensions, ImportType, SupportedMimeTypes } from './worksheet-import-contants';

const FILE_CONTROL_NAME = 'fileSource';

const MAX_FILE_SIZE = 10 * 1024 * 1024;

export function fileRequired() {
  return (group: UntypedFormGroup): { [key: string]: any } => {
    const file = group.get(FILE_CONTROL_NAME)?.value as File;
    return file ? null : { required: true };
  };
}

export function fileType() {
  return (group: UntypedFormGroup): { [key: string]: any } => {
    const importType = group.get('importType')?.value;
    const isJsonImport = importType && importType === ImportType.JSON;
    const isAgriImport = importType && importType === ImportType.AGRI;
    const supportedMimeTypes = isJsonImport ? SupportedMimeTypes.JSON : isAgriImport ? SupportedMimeTypes.AGRI : SupportedMimeTypes.ERO;
    const file = group.get(FILE_CONTROL_NAME)?.value;
    if (file) {
      const isFileExtensionValid = new RegExp('(' + AcceptedFileExtensions.join('|').replace(/\./g, '\\.') + ')$').test(
        file.name.toLocaleLowerCase()
      );
      if (!supportedMimeTypes.find(fileType => fileType === file.type) && !isFileExtensionValid) {
        return { fileType: true };
      }
    }
    return null;
  };
}

export function fileFormat() {
  return (group: UntypedFormGroup): Promise<{ [key: string]: any }> | Observable<{ [key: string]: any }> => {
    const importType = group.get('importType')?.value;
    const isJsonImport = importType && importType === ImportType.JSON;
    const file = group.get(FILE_CONTROL_NAME)?.value as File;
    const reader = new FileReader();
    const observ = new Observable<{ [key: string]: any }>((observer: Observer<{ [key: string]: any }>) => {
      reader.addEventListener('loadend', () => {
        if (isFormatValid(reader.result, isJsonImport)) {
          observer.next(null);
        } else {
          observer.next({ fileFormat: true });
        }
        observer.complete();
      });
      reader.readAsText(file);
    });

    return observ;
  };
}

export function fileSize() {
  return (group: UntypedFormGroup): { [key: string]: any } => {
    const file = group.get(FILE_CONTROL_NAME)?.value as File;
    if (file && file.size > MAX_FILE_SIZE) {
      return { maxSize: true };
    }

    return null;
  };
}

function isFormatValid(result: string | ArrayBuffer, isJsonImport: boolean) {
  if (!result) {
    return false;
  }
  if (isJsonImport) {
    try {
      const conent = JSON.parse(result as string);
      return conent && conent.worksheetTypeId;
    } catch (e) {
      return false;
    }
  }

  // XML
  const content = result.toString();
  // return (
  //   content.indexOf('<NmanFile>') !== -1 &&
  //   content.indexOf('</NmanFile>') !== -1 &&
  //   content.indexOf('<Project>') !== -1 &&
  //   content.indexOf('</Project>') !== -1
  // );
  return (containsTag(content, 'NmanFile') || containsTag(content, 'AgErosionFile')) && containsTag(content, 'Project');
}

function containsTag(source: string, tag: string) {
  return source.indexOf('<' + tag + '>') !== -1 && source.indexOf('</' + tag + '>') !== -1;
}
