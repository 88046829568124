import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NasmType } from '@nasm/model/nasm-type.model';
import { Observable } from 'rxjs';
import { URLs } from '../../core/conf/urls';
@Injectable({
  providedIn: 'root'
})
export class NasmLookupService {

  constructor(private httpClient: HttpClient) { }

  nasmTypes(): Observable<NasmType[]> {
    return this.httpClient.get(URLs.NASM_TYPES_ENDPOINT) as Observable<NasmType[]>;
  }

  depthOfUnsaturatedSoilRestriction(): Observable<any> {
    return this.httpClient.get(URLs.DEPTH_OF_UNSATURATED_SOIL_RESTRICTION_ENDPOINT);
  }
}