import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { routes } from '@core/conf/app.route';
import { msalProviders } from '@core/conf/msal.provider';

@NgModule({
  imports: [
    MsalModule,
    RouterModule.forRoot(routes, {
      useHash: false
    })
  ],
  exports: [RouterModule],
  providers: msalProviders,
  bootstrap: [MsalRedirectComponent]
})
export class AppRoutingModule {}
