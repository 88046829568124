<article id="importWorksheetDialogComponent">
    <h1 mat-dialog-title class="h1 h1--component-title">
        {{ data.title | translate | async }}
    </h1>

    <!-- Instructions -->
    <mat-horizontal-stepper linear #stepper (selectionChange)="onStepChange($event)" (animationDone)="applyAODAFixes()">
        <!-- Customize step icon -->
        <ng-template matStepperIcon="edit" let-index="index">
            <span (click)="goto(index)">{{ index + 1 }}</span>
        </ng-template>
        <ng-template matStepperIcon="done" let-index="index">
            <span (click)="goto(index)">{{ index + 1 }}</span>
        </ng-template>
        <ng-template matStepperIcon="number" let-index="index">
            <span (click)="goto(index)">{{ index + 1 }}</span>
        </ng-template>

        <mat-step>
            <ng-template matStepLabel>
                <p (click)="goto(0)">{{ "calculator.import.instructions" | translate | async }}</p>
            </ng-template>
            <p>{{ "calculator.import.instructions.desc" | translate | async }}</p>
            <ul id="importInstructions">
                <li>{{ "calculator.import.instructions.desc.1" | translate | async }}</li>
                <li>{{ "calculator.import.instructions.desc.2" | translate | async }}</li>
                <li>{{ "calculator.import.instructions.desc.3" | translate | async }}</li>
            </ul>

            <div>
                <button mat-button class="button button--red-secondary" [mat-dialog-close]="true">
                    {{ "dialog.action.cancel" | translate | async }}
                </button>
                <button mat-button matStepperNext class="button button--green-primary">
                    {{ "calculator.import.button.next.step" | translate | async }}
                </button>
            </div>
        </mat-step>

        <!-- File upload -->
        <mat-step [editable]="editable" [stepControl]="fbUpload">
            <div [formGroup]="fbUpload">
                <ng-template matStepLabel>{{ "calculator.import.select.upload" | translate | async }}</ng-template>
                <p>{{ "calculator.import.select.upload.desc" | translate | async }}</p>
                <div class="flex flex--cols-2">
                    <mat-form-field appearance="outline" class="flex-child mt-1 mb-1" color="accent">
                        <mat-label>{{ "calculator.import.select.upload.file.to.upload" | translate | async }}</mat-label>
                        <input matInput readonly formControlName="fileName" (click)="browseFile()" id="fileName" minlength="5" maxlength="255" [errorStateMatcher]="errorStateMatcher" />
                        <mat-error *ngIf="fbUpload.errors?.required">
                            {{ "calculator.import.error.file.required" | translate | async }}
                        </mat-error>
                        <mat-error *ngIf="fbUpload.errors?.fileType">
                            {{ "calculator.import.error.file.type" | translate | async }}
                        </mat-error>
                        <mat-error *ngIf="fbUpload.errors?.fileFormat" innerHTML="{{ 'calculator.import.error.file.format' | translate | async }}">
                            {{ "calculator.import.error.file.format" | translate | async }}
                        </mat-error>
                        <mat-error *ngIf="fbUpload.errors?.maxSize">
                            {{ "calculator.import.error.file.size" | translate : [10] | async }}
                        </mat-error>
                    </mat-form-field>

                    <button mat-raised-button (click)="browseFile()" class="button button--black-secondary">
                        {{ "calculator.import.select.upload.button.select.file" | translate | async }}
                    </button>

                    <input type="file" #fileUpload formControlName="file" [accept]="acceptedFileExt" (change)="onFileSelected($event)" class="input_fileupload--hidden" />
                </div>

                <div>
                    <button mat-button class="button button--red-secondary" [mat-dialog-close]="true">
                        {{ "dialog.action.cancel" | translate | async }}
                    </button>
                    <button mat-button (click)="upload().subscribe()" class="button button--green-primary">
                        {{ "calculator.import.button.next.step" | translate | async }}
                    </button>
                </div>
            </div>
        </mat-step>

        <!-- Edit details - Select calculator(s) -->
        <mat-step [editable]="editable" [stepControl]="fbWorksheets">
            <div [formGroup]="fbWorksheets" id="importDetails">
                <ng-template matStepLabel>{{ "calculator.import.edit.details" | translate | async }}</ng-template>
                <ng-container *ngIf="!worksheetsImport?.error; else noWorksheets">
                    <h5 class="mt-0">{{ "calculator.import.edit.details.found.calculator" | translate : [allWorksheets?.length] | async }}</h5>
                    <p>{{ "calculator.import.edit.details.select.calculator" | translate | async }}</p>
                    <div id="worksheetList" class="mb-1">
                        <div class="flex flex--cols-3 {{ isInvalidWorksheet(worksheet) ? 'align-baseline' : 'align-end' }}" *ngFor="let worksheet of allWorksheets; let i = index">
                            <div class="flex-child pr-1 ws-checkbox" *ngIf="allWorksheets.length > 1">
                                <mat-checkbox
                                    [value]="worksheet.id"
                                    [class.disabled]="isInvalidWorksheet(worksheet)"
                                    role="checkbox"
                                    (change)="onCheckboxChange($event)"
                                    attr.aria-label="{{ worksheet.name }}">
                                </mat-checkbox>
                            </div>
                            <div class="flex-child ws-name">
                                <ng-container *ngIf="!isEditing(i); else editWorksheetName"
                                    >{{ worksheet.name }}
                                    <mat-icon
                                        *ngIf="!isInvalidWorksheet(worksheet)"
                                        color="accent"
                                        tabindex="0"
                                        aria-hidden="false"
                                        mat-icon-button
                                        class="button button--green-tertiary"
                                        matTooltip="{{ 'calculator.name.change' | translate | async }}"
                                        matTooltipPosition="above"
                                        (click)="toggleEditMode($event, i)"
                                        (keyup.enter)="toggleEditMode($event, i)"
                                        attr.aria-label="{{ 'change.calculator.name' | translate | async }}"
                                        >edit</mat-icon
                                    >
                                </ng-container>
                                <ng-template #editWorksheetName>
                                    <mat-form-field appearance="outline" class="flex-child mb-0" color="accent">
                                        <input
                                            matInput
                                            trim="blur"
                                            (blur)="onNameChange($event, i)"
                                            maxlength="30"
                                            autoFocusByContent
                                            formControlName="worksheetName"
                                            attr.aria-label="{{ 'calculator.name.placeholder' | translate | async }}" />
                                        <mat-error *ngIf="worksheetName.errors?.minlength">
                                            {{ "validation.message.minLength.3" | translate | async }}
                                        </mat-error>
                                        <mat-error *ngIf="worksheetName.errors?.maxlength">
                                            {{ "validation.message.maxLength.30" | translate | async }}
                                        </mat-error>
                                        <mat-error *ngIf="worksheetName.errors?.empty">
                                            {{ "validation.message.empty" | translate | async }}
                                        </mat-error>
                                    </mat-form-field>
                                </ng-template>
                            </div>
                            <div class="flex-child flex-child--flex-grow pl-1">
                                ({{ getWorksheetType(worksheet.worksheetTypeId)
                                }}<span *ngIf="isInvalidWorksheet(worksheet)"> - {{ "calculator.import.error." + getErrorCode(worksheet) | translate | async }}</span
                                >)
                            </div>
                        </div>
                    </div>
                    <div class="mb-1" *ngIf="!selectedWorksheetIds?.pristine && selectedWorksheetIds.errors?.required">
                        <mat-error>
                            {{ "calculator.import.error.calculator.required" | translate | async }}
                        </mat-error>
                    </div>
                </ng-container>
                <ng-template #noWorksheets>
                    <div id="worksheetList" class="colour--red-primary mb-1" innerHTML="{{ 'calculator.import.error.' + worksheetsImport?.error.errorCode | translate | async }}">
                        {{ "calculator.import.error." + worksheetsImport?.error.errorCode | translate | async }}
                    </div>
                </ng-template>

                <div>
                    <button mat-button class="button button--red-secondary" [mat-dialog-close]="true">
                        {{ "dialog.action.cancel" | translate | async }}
                    </button>
                    <button mat-button (click)="import()" class="button button--green-primary">
                        {{ "calculator.import.button.next.step" | translate | async }}
                    </button>
                </div>
            </div>
        </mat-step>

        <!-- Complete -->
        <mat-step [editable]="editable">
            <ng-template matStepLabel>{{ "calculator.import.complete" | translate | async }}</ng-template>
            <h5 class="mt-0">{{ "calculator.import.complete.success" | translate : [importedWorksheets.length, selectedWorksheetIds.length] | async }}</h5>
            <p>{{ "calculator.import.complete.success.desc" | translate | async }}</p>
            <div id="importedList" class="mb-1">
                <div *ngFor="let worksheet of importedWorksheets" class="pb-1">
                    <a role="button" (click)="gotoWorksheet(worksheet)">
                        {{ worksheet.name }}
                    </a>
                    ({{ getWorksheetType(worksheet.worksheetTypeId) }})
                </div>
            </div>
            <div>
                <button mat-button class="button button--red-secondary" [mat-dialog-close]="true">
                    {{ "dialog.action.close" | translate | async }}
                </button>
                <button mat-button matStepperNext (click)="goto(0)" class="button button--green-primary">
                    {{ "calculator.import.button.start.another" | translate | async }}
                </button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</article>
