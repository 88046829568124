import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLs } from '../../core/conf/urls';
@Injectable({
  providedIn: 'root'
})
export class MstorLookupService {
  constructor(private httpClient: HttpClient) {}

  livestockTypes(): Observable<any[]> {
    return this.httpClient.get(URLs.LIVESTOCK_TYPE_ENDPOINT) as Observable<any[]>;
  }

  treatmentTypes(): Observable<any[]> {
    return this.httpClient.get(URLs.TREATMENT_TYPE_ENDPOINT) as Observable<any[]>;
  }

  otherSourceTypes() {
    return this.httpClient.get(URLs.OTHER_SOURCE_TYPE_ENDPOINT) as Observable<any[]>;
  }

  transferTypes() {
    return this.httpClient.get(URLs.TRANSFER_TYPE_ENDPOINT) as Observable<any[]>;
  }

  livestockCombinations() {
    return this.httpClient.get(URLs.LIVESTOCK_COMBINATIONS_ENDPOINT) as Observable<any[]>;
  }

  livestockCombinationDetails() {
    return this.httpClient.get(URLs.LIVESTOCK_COMBINATIONS_DETAILS_ENDPOINT) as Observable<any[]>;
  }

  storageTypes() {
    return this.httpClient.get(URLs.STORAGE_TYPE_ENDPOINT) as Observable<any[]>;
  }

  storageDimensions() {
    return this.httpClient.get(URLs.STORAGE_DIMENSIONS_ENDPOINT) as Observable<any[]>;
  }

  storageDimensionLinks() {
    return this.httpClient.get(URLs.STORAGE_DIMENSION_LINKS_ENDPOINT) as Observable<any[]>;
  }

  storageChecklists() {
    return this.httpClient.get(URLs.STORAGE_CHECKLIST_ENDPOINT) as Observable<any[]>;
  }

  storageTemporaries() {
    return this.httpClient.get(URLs.STORAGE_TEMPORARIES_ENDPOINT) as Observable<any[]>;
  }

  storageTemporaryOptions() {
    return this.httpClient.get(URLs.STORAGE_TEMPORARY_OPTIONS_ENDPOINT) as Observable<any[]>;
  }

  runoffManagementOptions() {
    return this.httpClient.get(URLs.STORAGE_RUNOFF_MANAGEMENT_OPTIONS_ENDPOINT) as Observable<any[]>;
  }

  livestockTypeDietCommon() {
    return this.httpClient.get(URLs.LIVESTOCK_TYPE_DIET_COMMON_ENDPOINT) as Observable<any[]>;
  }
}
