import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLs } from '@core/conf/urls';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgeLookupService {
  constructor(private httpClient: HttpClient) {}

  climateStationIdfCurve() {
    return this.httpClient.get(URLs.CLIMATE_STATION_IDF_CURVE_ENDPOINT) as Observable<any[]>;
  }

  controlStructureType() {
    return this.httpClient.get(URLs.CONTROL_STRUCTURE_TYPE_ENDPOINT) as Observable<any[]>;
  }

  climateStationUpperTierLink() {
    return this.httpClient.get(URLs.CLIMATE_STATION_UPPER_TIER_LINK_ENDPOINT) as Observable<any[]>;
  }

  runoffCurveNumber() {
    return this.httpClient.get(URLs.RUNOFF_CURVE_NUMBER_ENDPOINT) as Observable<any[]>;
  }

  waterwayLining() {
    return this.httpClient.get(URLs.WATERWAY_LINING_ENDPOINT) as Observable<any[]>;
  }

  soilErosionCrop() {
    return this.httpClient.get(URLs.SOIL_EROSION_CROP) as Observable<any[]>;
  }

  flowVelocityCoefficients() {
    return this.httpClient.get(URLs.FLOW_VELOCITY_COEFF_ENDPOINT) as Observable<any[]>;
  }
}
