import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLs } from '@core/conf/urls';
import { MaterialNutrientCode } from '@shared/models/material/material-nutrient-code';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MaterialNutrientCodeService {
  constructor(private httpClient: HttpClient) { }

  all(): Observable<MaterialNutrientCode[]> {
    return this.httpClient.get(URLs.MATERIAL_NUTRIENT_CODE_ENDPOINT) as Observable<MaterialNutrientCode[]>;
  }
}
