import { AgeLookupService } from '@ag-erosion/service/age-lookup.service';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CropNutrientService } from '@crop-nutrient/crop-nutrient.service';
import { FertilizerProductService } from '@fertilizer/service/fertilizer-product.service';
import { GhgLookupService } from '@ghg/service/ghg-lookup.service';
import { GnfLookupService } from '@gnf/service/gnf-lookup.service';
import { ApplicationIncorporationService } from '@organic-amendment/service/application-incorporation.service';
import { ApplicationMethodService } from '@organic-amendment/service/application-method.service';
import { MaterialNutrientCodeService } from '@organic-amendment/service/material-nutrient-code.service';
import { OrganicAmendmentService } from '@organic-amendment/service/organic-amendment.service';
import { FertilizerApplicationIncorporationService } from '@plato/service/fertilizer-application-incorporation.service';
import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Constants } from '../constants/constants';
import { CacheService } from './cache.service';
import { CropLookupService } from './crop-lookup.service';
import { FlagService } from './flag.service';
import { LocationService } from './location.service';
import { MDSLookupService } from './mds-lookup.service';
import { MstorLookupService } from './mstor-lookup.service';
import { NasmLookupService } from './nasm-lookup.service';
import { NmspLookupService } from './nmsp-lookup.service';
import { WorksheetTypeLookupService } from './worksheet-type-lookup.service';

@Injectable({
  providedIn: 'root'
})
export class APICacheService {
  constructor(
    private httpClient: HttpClient,
    private handler: HttpBackend,
    private cache: CacheService,
    private worksheetTypeLookupService: WorksheetTypeLookupService,
    private materialNutrientCodeService: MaterialNutrientCodeService,
    private flagService: FlagService,
    private cropLookupService: CropLookupService,
    private cropNutrientService: CropNutrientService,
    private locationService: LocationService,
    private fertilizerApplicationIncorporationService: FertilizerApplicationIncorporationService,
    private applicationIncorporationService: ApplicationIncorporationService,
    private organicAmendmentService: OrganicAmendmentService,
    private fertilizerProductService: FertilizerProductService,
    private applicationMethodService: ApplicationMethodService,
    private storageSizingLookupService: MstorLookupService,
    private mdsLookupService: MDSLookupService,
    private nmspLookupService: NmspLookupService,
    private nasmLookupService: NasmLookupService,
    private gnfLookupService: GnfLookupService,
    private ghgLookupService: GhgLookupService,
    private ageLookupService: AgeLookupService
  ) {
    this.httpClient = new HttpClient(this.handler);
  }

  create(buildVersion: string): Observable<any> {
    const isNewBuild = buildVersion !== this.cache.buildVersion;
    if (isNewBuild || this.shouldFetch) {
      return this.run().pipe(
        tap(() => {
          this.cache.setTimestamp();
          this.cache.setHash();
        })
      );
    } else {
      Constants['nutrientCodes'] = this.cache.nutrientCodes; // this is bad, removing soon
      return this.cacheI18n(); // need to load i18n files on every reload, do something about this
    }
  }

  get shouldFetch() {
    return this.cache.empty || this.cache.expired || this.cache.adding;
  }

  run(): Observable<any> {
    let cacheables = [
      this.cacheI18n(),
      this.cacheWorksheetTypes(),
      this.cacheMaterialNutrientCodes(),
      this.cacheSoilTestCodes(),
      this.cacheAllFlagTypes(),
      this.cacheCropTypes(),
      this.cacheNitrogenCredits(),
      this.cacheSoilTextures(),
      this.cacheSoilSeries(),
      this.cacheUpperTierMunicipalities(),
      this.cacheLowerTierMunicipalities(),
      this.cacheGeoTownships(),
      this.cacheTillageMethods(),
      this.cacheTillagePractices(),
      this.cacheFertilizerIncorporation(),
      this.cacheMaterialIncorporation(),
      this.cacheCropRotations(),
      this.cacheAllMaterialTypes(),
      this.cacheAllDryRanges(),
      this.cacheFertilizerProducts(),
      this.cacheApplicationMethods(),
      this.cacheLivestockTypes(),
      this.cacheTreatmentTypes(),
      this.cacheOtherSourceTypes(),
      this.cacheTransferTypes(),
      this.cacheLivestockCombinations(),
      this.cacheLivestockCombinationDetails(),
      this.cacheStorageTypes(),
      this.cacheStorageDimensions(),
      this.cacheStorageDimensionLinks(),
      this.cacheStorageChecklists(),
      this.cacheStorageTemporaries(),
      this.cacheStorageTemporaryOptions(),
      this.cacheRunoffManagementOptions(),
      this.cacheLandUseTypes(),
      this.cacheMdsMaterialTypes(),
      this.cacheMdsMaterialSubTypes(),
      this.cacheMdsStorageTypes(),
      this.cacheEquationValues(),
      this.cacheOverviewOperations(),
      this.cacheNasmTypes(),
      this.cacheDepthOfUnsaturatedSoilRestriction(),
      this.cacheHorticulturalType(),
      this.cacheLivestockTypeDietCommon(),
      this.cacheBuildingInsulationLevels(),
      this.cacheBuildingInfiltrationLevels(),
      this.cacheHeatingTypes(),
      this.cacheFuelTypes(),
      this.cacheTreeSpecies(),
      this.cacheFeedAdditives(),
      this.cacheGreenhouseCoveringMaterial(),
      this.cacheGreenhouseConstructionLevel(),
      this.cacheGreenhouseThermalCurtain(),
      this.cacheGreenhouseCropType(),
      this.cacheGreenhouseLightingType(),
      this.cacheGrainDryerType(),
      this.cacheGrainDryerCropType(),
      this.cacheClimateStationIdfCurve(),
      this.cacheClimateStationUpperTierLink(),
      this.cacheRunoffCurveNumber(),
      this.cacheControlStructureType(),
      this.cacheWaterwayLining(),
      this.cacheSoilErosionCrop(),
      this.cacheFlowVelocityCoefficients()
    ];
    return forkJoin(cacheables);
  }

  private cacheWorksheetTypes() {
    return this.worksheetTypeLookupService.worksheetTypes().pipe(tap(worksheetTypes => (this.cache.worksheetTypes = worksheetTypes)));
  }

  private cacheGeoTownships() {
    return this.locationService.allGeoTownships().pipe(tap(geoTownships => (this.cache.geoTownships = geoTownships)));
  }

  private cacheUpperTierMunicipalities() {
    return this.locationService
      .upperTierMunicipalities()
      .pipe(tap(upperTierMunicipalities => (this.cache.upperTierMunicipalities = upperTierMunicipalities)));
  }

  private cacheLowerTierMunicipalities() {
    return this.locationService.lowerTierMunicipalities().pipe(
      tap(
        lowerTierMunicipalities =>
          (this.cache.lowerTierMunicipalities = lowerTierMunicipalities.map(l => ({
            id: l.id,
            lowerTierCode: Number(l.lowerTierCode) ? Number(l.lowerTierCode) : l.lowerTierCode,
            lowerTierName: l.lowerTierName,
            rollNumberCode: l.rollNumberCode,
            upperTierCode: l.upperTierCode
          })))
      )
    );
  }

  private cacheMaterialNutrientCodes() {
    return this.materialNutrientCodeService.all().pipe(
      tap(data => {
        if (data) {
          this.cache.nutrientCodes = data;
          // tslint:disable-next-line: no-string-literal
          Constants['nutrientCodes'] = data;
        }
      })
    );
  }

  private cacheSoilTestCodes() {
    return this.cropLookupService.soilTestCodes().pipe(tap(data => (this.cache.soilTestCodes = data)));
  }

  private cacheI18n() {
    const en = environment.i18nDirectory + 'en.json';
    const fr = environment.i18nDirectory + 'fr.json';
    return forkJoin([this.httpClient.get(en), this.httpClient.get(fr)]).pipe(tap((res: any) => ([this.cache.en, this.cache.fr] = res)));
  }

  private cacheCropTypes() {
    return forkJoin([this.cropNutrientService.allCropTypes(), this.cropNutrientService.allCropSubTypes()]).pipe(
      tap(responses => {
        if (!responses[0]) {
          return;
        }
        this.cache.allCropTypes = responses[0];
        this.cache.allCropSubTypes = responses[1];
      })
    );
  }

  private cacheEquationValues() {
    return this.flagService.equationValues().pipe(tap(data => (this.cache.equationValues = data)));
  }

  private cacheNitrogenCredits() {
    return this.cropNutrientService.allNitrogenCredits().pipe(tap(data => (this.cache.allNitrogenCredits = data)));
  }

  private cacheSoilTextures() {
    return this.cropLookupService.allSoilTextures().pipe(tap(data => (this.cache.allSoilTextures = data)));
  }

  private cacheSoilSeries() {
    return this.cropLookupService.soilSeries(-1).pipe(tap(data => (this.cache.soilSeries = data)));
  }

  private cacheTillageMethods() {
    return this.cropLookupService.allTillageMethods().pipe(tap(data => (this.cache.allTillageMethods = data)));
  }

  private cacheTillagePractices() {
    return this.cropLookupService.allTillagePractices().pipe(tap(data => (this.cache.allTillagePractices = data)));
  }

  private cacheAllMaterialTypes() {
    return this.organicAmendmentService.getAllMaterialTypeOptions().pipe(tap(data => (this.cache.allMaterialTypes = data)));
  }

  private cacheFertilizerIncorporation() {
    return this.fertilizerApplicationIncorporationService.all().pipe(tap(data => (this.cache.fertilizerIncorporations = data)));
  }

  private cacheMaterialIncorporation() {
    return this.applicationIncorporationService.all().pipe(tap(data => (this.cache.materialIncorporations = data)));
  }

  private cacheCropRotations() {
    return this.cropLookupService.cropRotations().pipe(tap(data => (this.cache.cropRotations = data)));
  }

  private cacheAllFlagTypes() {
    return this.flagService.allTypes().pipe(tap(flagTypes => (this.cache.flagTypes = flagTypes)));
  }

  private cacheAllDryRanges() {
    return this.organicAmendmentService.getAllDryMatterRanges().pipe(tap(data => (this.cache.allDryRanges = data)));
  }

  private cacheFertilizerProducts() {
    return this.fertilizerProductService.all().pipe(tap(data => (this.cache.fertilizerProducts = data)));
  }

  private cacheApplicationMethods() {
    return this.applicationMethodService.all().pipe(tap(data => (this.cache.applicationMethods = data)));
  }

  private cacheLivestockTypes() {
    return this.storageSizingLookupService.livestockTypes().pipe(tap(data => (this.cache.livestockTypes = data)));
  }

  private cacheTreatmentTypes() {
    return this.storageSizingLookupService.treatmentTypes().pipe(tap(data => (this.cache.treatmentTypes = data)));
  }

  private cacheOtherSourceTypes() {
    return this.storageSizingLookupService.otherSourceTypes().pipe(tap(data => (this.cache.otherSourceTypes = data)));
  }

  private cacheTransferTypes() {
    return this.storageSizingLookupService.transferTypes().pipe(tap(data => (this.cache.transferTypes = data)));
  }

  private cacheLivestockCombinations() {
    return this.storageSizingLookupService.livestockCombinations().pipe(tap(data => (this.cache.livestockCombinations = data)));
  }

  private cacheLivestockCombinationDetails() {
    return this.storageSizingLookupService.livestockCombinationDetails().pipe(tap(data => (this.cache.livestockCombinationDetails = data)));
  }

  private cacheStorageTypes() {
    return this.storageSizingLookupService.storageTypes().pipe(tap(data => (this.cache.storageTypes = data)));
  }

  private cacheStorageDimensions() {
    return this.storageSizingLookupService.storageDimensions().pipe(tap(data => (this.cache.storageDimensions = data)));
  }

  private cacheStorageDimensionLinks() {
    return this.storageSizingLookupService.storageDimensionLinks().pipe(tap(data => (this.cache.storageDimensionLinks = data)));
  }

  private cacheStorageChecklists() {
    return this.storageSizingLookupService.storageChecklists().pipe(tap(data => (this.cache.storageChecklists = data)));
  }

  private cacheStorageTemporaries() {
    return this.storageSizingLookupService.storageTemporaries().pipe(tap(data => (this.cache.storageTemporaries = data)));
  }

  private cacheStorageTemporaryOptions() {
    return this.storageSizingLookupService.storageTemporaryOptions().pipe(tap(data => (this.cache.storageTemporaryOptions = data)));
  }

  private cacheRunoffManagementOptions() {
    return this.storageSizingLookupService.runoffManagementOptions().pipe(tap(data => (this.cache.runoffManagementOptions = data)));
  }

  private cacheLandUseTypes() {
    return this.mdsLookupService.landUseTypes().pipe(tap(data => (this.cache.landUseTypes = data)));
  }

  private cacheMdsMaterialTypes() {
    return this.mdsLookupService.mdsMaterialTypes().pipe(tap(data => (this.cache.mdsMaterialTypes = data)));
  }

  private cacheMdsMaterialSubTypes() {
    return this.mdsLookupService.mdsMaterialSubTypes().pipe(tap(data => (this.cache.mdsMaterialSubTypes = data)));
  }

  private cacheMdsStorageTypes() {
    return this.mdsLookupService.mdsStorageTypes().pipe(tap(data => (this.cache.mdsStorageTypes = data)));
  }

  private cacheOverviewOperations() {
    return this.nmspLookupService.overviewOperations().pipe(tap(data => (this.cache.overviewOperations = data)));
  }

  private cacheNasmTypes() {
    return this.nasmLookupService.nasmTypes().pipe(tap(data => (this.cache.nasmTypes = data)));
  }

  private cacheDepthOfUnsaturatedSoilRestriction() {
    return this.nasmLookupService
      .depthOfUnsaturatedSoilRestriction()
      .pipe(tap(data => (this.cache.depthOfUnsaturatedSoilRestriction = data)));
  }

  private cacheHorticulturalType() {
    return this.gnfLookupService.horticulturalType().pipe(tap(data => (this.cache.horticulturalType = data)));
  }

  private cacheLivestockTypeDietCommon() {
    return this.storageSizingLookupService.livestockTypeDietCommon().pipe(tap(data => (this.cache.livestockTypeDietCommon = data)));
  }

  private cacheBuildingInsulationLevels() {
    return this.ghgLookupService.buildingInsulationLevels().pipe(tap(data => (this.cache.buildingInsulationLevels = data)));
  }

  private cacheHeatingTypes() {
    return this.ghgLookupService.heatingTypes().pipe(tap(data => (this.cache.heatingTypes = data)));
  }

  private cacheFuelTypes() {
    return this.ghgLookupService.fuelTypes().pipe(tap(data => (this.cache.fuelTypes = data)));
  }

  private cacheBuildingInfiltrationLevels() {
    return this.ghgLookupService.buildingInfiltrationLevels().pipe(tap(data => (this.cache.buildingInfiltrationLevels = data)));
  }

  private cacheTreeSpecies() {
    return this.ghgLookupService.treeSpecies().pipe(tap(data => (this.cache.treeSpecies = data)));
  }

  private cacheFeedAdditives() {
    return this.ghgLookupService.feedAdditives().pipe(tap(data => (this.cache.feedAdditives = data)));
  }

  private cacheGreenhouseCoveringMaterial() {
    return this.ghgLookupService.greenhouseCoveringMaterial().pipe(tap(data => (this.cache.greenhouseCoveringMaterial = data)));
  }

  private cacheGreenhouseConstructionLevel() {
    return this.ghgLookupService.greenhouseConstructionLevel().pipe(tap(data => (this.cache.greenhouseConstructionLevel = data)));
  }

  private cacheGreenhouseThermalCurtain() {
    return this.ghgLookupService.greenhouseThermalCurtain().pipe(tap(data => (this.cache.greenhouseThermalCurtain = data)));
  }

  private cacheGreenhouseCropType() {
    return this.ghgLookupService.greenhouseCropType().pipe(tap(data => (this.cache.greenhouseCropType = data)));
  }

  private cacheGreenhouseLightingType() {
    return this.ghgLookupService.greenhouseLightingType().pipe(tap(data => (this.cache.greenhouseLightingType = data)));
  }

  private cacheGrainDryerType() {
    return this.ghgLookupService.grainDryerType().pipe(tap(data => (this.cache.grainDryerType = data)));
  }

  private cacheGrainDryerCropType() {
    return this.ghgLookupService.grainDryerCropType().pipe(tap(data => (this.cache.grainDryerCropType = data)));
  }

  private cacheClimateStationIdfCurve() {
    return this.ageLookupService.climateStationIdfCurve().pipe(tap(data => (this.cache.climateStationIdfCurve = data)));
  }

  private cacheClimateStationUpperTierLink() {
    return this.ageLookupService.climateStationUpperTierLink().pipe(tap(data => (this.cache.climateStationUpperTierLink = data)));
  }

  private cacheRunoffCurveNumber() {
    return this.ageLookupService.runoffCurveNumber().pipe(tap(data => (this.cache.runoffCurveNumber = data)));
  }

  private cacheControlStructureType() {
    return this.ageLookupService.controlStructureType().pipe(tap(data => (this.cache.controlStructureType = data)));
  }

  private cacheWaterwayLining() {
    return this.ageLookupService.waterwayLining().pipe(tap(data => (this.cache.waterwayLining = data)));
  }

  private cacheSoilErosionCrop() {
    return this.ageLookupService.soilErosionCrop().pipe(tap(data => (this.cache.soilErosionCrop = data)));
  }

  private cacheFlowVelocityCoefficients() {
    return this.ageLookupService.flowVelocityCoefficients().pipe(tap(data => (this.cache.flowVelocityCoefficients = data)));
  }

  get isProd(): boolean {
    return environment.isProdEnv ? true : false;
  }
}
